import React from 'react';
import InternetGloboSvg from '../../assets/images/internet-globo.svg'
import TelefonistaSvg from '../../assets/images/telefonista.svg'
import CelularSvg from '../../assets/images/celular.svg'
import FoneSvg from '../../assets/images/fone.svg'
import  './PainelCanaisOficiais.css'

const PainelCanaisOficiais = () => (
    <>
        <div className="row">
            <span className='canais-oficiais-msg'>
                Emita seus boletos pelos nossos canais oficiais:
            </span>
        </div>
        <div className="row">
            <div className='verticalListItem'>
                <div className='verticalItemImage'>
                    <img src={InternetGloboSvg} className="img-canais" />
                </div>
                <div className="verticalItemText">
                    <p className="canais-label">
                        Portal Unimed Goiânia:
                    </p>
                    <p className="canais-text">
                        unimedgoiania.coop.br
                    </p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className='verticalListItem'>
                <div className='verticalItemImage'>
                    <img src={TelefonistaSvg} className="img-canais" />
                </div>
                <div className="verticalItemText">
                    <p className="canais-label">
                        Assistente Virtual - Jane:
                    </p>
                    <p className="canais-text">
                        Via portal ou WhatsApp: (62) 3216-8000
                    </p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className='verticalListItem'>
                <div className='verticalItemImage'>
                    <img src={CelularSvg} className="img-canais" />
                </div>
                <div className="verticalItemText">
                    <p className="canais-label">
                        Super App Unimed Goiânia:
                    </p>
                    <p className="canais-text">
                        Disponível para Android e IOS
                    </p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className='verticalListItem'>
                <div className='verticalItemImage'>
                    <img src={FoneSvg} className="img-canais" />
                </div>
                <div className="verticalItemText">
                    <p className="canais-label">
                        Central de atendimento:
                    </p>
                    <p className="canais-text">
                        0800 642 8008
                    </p>
                </div>
            </div>
        </div>
    </>
)

export default PainelCanaisOficiais;