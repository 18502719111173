
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import './App.css';
import PaginaValidarBoleto from "./pages/validar-boleto/PaginaValidarBoleto.tsx"


function App() {

  return (
    <div id="wrapper ">
        <Routes>
          <Route exact path="/" element={<PaginaValidarBoleto />} />
          <Route exact path="/validar" element={<PaginaValidarBoleto />} />
        </Routes>
      </div>
  );
}


export default App;
