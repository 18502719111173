import "./Botao.css"

const Botao = (props) => {
    return (
        < div className="campo-botao">
            <label>{props.label}</label>
            <button
                className="botao"
                disabled={props.disabled}>
                {props.children}
            </button>
        </div>
    )
}
export default Botao