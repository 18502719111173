import { useState } from "react";
import "./CampoTexto.css"
import PastImputSvg from '../../assets/images/past-input.svg'

const CampoTexto = (props) => {

    async function get_clip() {
        try {
            return navigator.clipboard.readText();
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    }

    async function onGetClipboard() {
        const text = await get_clip();
        console.log("Texto Colado:" + text);
        var event = new Event('change', { bubbles: true });
        var element = document.getElementById('input-text-id');
        setNativeValue(element, text);
        // element.setAttribute('value', text);
        element.dispatchEvent(event);
    }

    function setNativeValue(element, value) {
        const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
        const prototype = Object.getPrototypeOf(element);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value);
        } else {
            valueSetter.call(element, value);
        }
    }

    const aoDigitar = (evento) => {
        props.novoValor(evento.target.value);
    }

    return (
        <div className="campo-texto">
            <label>{props.label}</label>
            <div className="ctbox">
                <input
                    id="input-text-id"
                    value={props.valor}
                    onChange={aoDigitar}
                    disabled={props.disabled}
                    required={props.obrigatorio}
                    placeholder={props.placeholder}
                    minLength={props.minLength}
                />
                {!props.disabled && <img src={PastImputSvg} className="img-past-input" onClick={() => onGetClipboard()} />}
            </div>
        </div >
    )
}

export default CampoTexto