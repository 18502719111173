import React, { useState } from 'react';
import "./BoletoCodigoHelp.css";

//import BoletoCodigoHelpSvg from '../../assets/images/boleto-codigo-help.svg'
import BoletoCodigoHelpPng from '../../assets/images/boleto-codigo-help.png'
import Interrogacao from '../../assets/images/question-circle-interrogacao.svg'

const BoletoCodigoHelp = ({ largura }) => {

    const [isOpen, setIsOpen] = useState(false);

    function onHideViewImage() {
        setIsOpen(isOpen ? false : true);
    }



    return (
        <>
            {largura <= 768 &&
                <img
                    className="image-link-help-codigo-boleto"
                    src={Interrogacao}
                    onClick={() => onHideViewImage()}
                    alt="no image help boleto"
                />}
            <div className='label-link-help-codigo-boleto'>
                {largura > 768 &&
                    <span onClick={() => onHideViewImage()}>
                        Onde encontro o código?
                    </span>
                }
            </div>
            {isOpen &&
                <dialog
                    className="dialog"
                    style={{ position: "absolute" }}
                    open
                    onClick={() => onHideViewImage()}
                >
                    <img
                        className="image"
                        src={BoletoCodigoHelpPng}
                        onClick={() => onHideViewImage()}
                        alt="no image boleto"
                    />
                </dialog>
            }
        </>
    )
}

export default BoletoCodigoHelp;

