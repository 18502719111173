import React, { useState, useEffect, useRef } from 'react';
import BarrasVerticaisSvg from '../../assets/images/barras-verticais.svg'
import CameraSvg from '../../assets/images/camera.svg'
import AvancarSvg from '../../assets/images/avancar.svg'
import Scanner from '../../componentes/Scanner/Scanner.tsx'
import './PainelCameraDetect.css'
import EstagioEnum from '../../enums/EstagioEnum.tsx';


const PainelCameraDetect = ({ setEstagio, setStringPesquisaUser }) => {

    const [scanning, setScanning] = useState(false);
    const [results, setResults] = useState([]);
    const scannerRef = useRef(null);

    function onPesquisar() {
        setStringPesquisaUser("");
        setEstagio(EstagioEnum.PESQUISAR);
    }

    function onScannerCamera() {
        setEstagio(EstagioEnum.SCAN);
        setScanning(true);
    }

    function onAbortScanner() {
        setEstagio(EstagioEnum.INIT_CAMERA);
        setScanning(false);
    }
    useEffect(() => {
        
        results.map((result) => {
            console.log("qtdCod:"+String(result).length);
            console.log("Codigo:"+result);
            if (String(result).length == 44) {
                setScanning(false);
                setStringPesquisaUser(result);
                setEstagio(EstagioEnum.PESQUISAR);
            }
        });
        console.log("Results Scan:" + results);
    }, [results]);


    return (
        <div className='painel-camera-detect'>
            {!scanning &&
                <div>
                    <div className="row">
                        <div className='<className="col-md-6 col-xm-12 verticalListItem' onClick={() => onScannerCamera()}>
                            <div className='verticalItemImageLef'>
                                <img src={CameraSvg} className="img-camera-svg" />
                            </div>
                            <div className="verticalItemText">
                                <p className="camera-detect-text">
                                    Use a câmera
                                </p>
                            </div>
                            <div className='verticalItemImageRig'>
                                <img src={AvancarSvg} className="img-avancar-svg" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='className="col-md-6 col-xm-12 verticalListItem' onClick={() => onPesquisar()}>
                            <div className='verticalItemImageLef'>
                                <img src={BarrasVerticaisSvg} className="img-barras-verticais-svg" />
                            </div>
                            <div className="verticalItemText">
                                <p className="camera-detect-text">
                                    Digite ou cole o código do boleto
                                </p>
                            </div>
                            <div className='verticalItemImageRig'>
                                <img src={AvancarSvg} className="img-avancar-svg" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {scanning &&
                <div>
                    <div className='row text-abort-scanner' onClick={() => onAbortScanner()}>
                        <p>Não consegue fazer a leitura?</p>
                    </div>
                    <div className="container">
                        <div id="scannerWrapper" ref={scannerRef} style={{ position: "relative", border: "1px solid red" }}      >
                            {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
                            <canvas
                                className="drawingBuffer"
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    // left: '0px',
                                    // height: '100%',
                                    // width: '100%',
                                    border: "3px solid green"
                                }}
                                width="640px"
                                height="480px"
                            />
                            {scanning ? (
                                <Scanner
                                    scannerRef={scannerRef}
                                    onDetected={(result) => setResults([...results, result])}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PainelCameraDetect;