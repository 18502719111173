import React from 'react';
import './PainelAvisoSeguranca.css'

const PainelAvisoSeguranca = () => (
    <>
        <p className='aviso-seguranca-titulo'>
            Mais segurança para a sua rotina.
        </p>
        <p className='aviso-seguranca'>
            Sempre que for pagar um novo boleto da Unimed Goiânia, lembre-se de verificar se ele realmente foi emitido pela cooperativa. Conte conosco!
        </p>
    </>
)

export default PainelAvisoSeguranca;