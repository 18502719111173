import React from "react";

const headers = new Headers({
    "accept": 'application/json'
})

async function verificarBoleto(stringPesquisa) {

    const endPoint = `/api/verificar-boleto?termo-boleto=${stringPesquisa}`

    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch(endPoint, options);
    const data = await response.json();
    return data;
}

const CallbackApiService = {
    verificarBoleto
}

export default CallbackApiService;