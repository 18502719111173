
import React, { useState, useEffect } from 'react';
import "./PaginaValidarBoleto.css";
import Botao from "../../componentes/Botao/Botao.js";
import CampoTexto from "../../componentes/CampoTexto/CampoTexto.js";

import Loading3Sharps from "../../componentes/Loading/Loading3Sharps.js";

import ResultadoValidarBoletoEnum from '../../enums/ResultadoValidarBoletoEnum.ts';
import IValidarBoletoResponse from '../../interfaces/IValidarBoletoResponse.tsx';
import CallbackApiService from "../../services/CallbackApiService.js";
import BarrasVerticaisSvg from '../../assets/images/barras-verticais.svg'
import ValidarBoletoSvg from '../../assets/images/validar-boleto.svg'
import LogoUnimedPng from '../../assets/images/logo-unimed.png'
import VoltarSvg from '../../assets/images/voltar.svg'

import EstagioEnum from '../../enums/EstagioEnum.tsx';

import PainelCanaisOficiais from './PainelCanaisOficiais.tsx';
import PainelAvisoSeguranca from './PainelAvisoSeguranca.tsx';
import PainelCameraDetect from './PainelCameraDetect.tsx';
import BoletoCodigoHelp from './BoletoCodigoHelp.tsx';

const PaginaValidarBoleto = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const [resultApi, setResultApi] = useState<IValidarBoletoResponse>({});
    const [stringPesquisaUser, setStringPesquisaUser] = useState("")
    const [bloquearBotao, setBloquearBotao] = useState(true)
    const [estagio, setEstagio] = useState(() => getEstagioInicial());
    const [largura, setLargura] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            var lw = window.innerWidth;
            setLargura(lw);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setStringPesquisaUser(formatarDigitos(stringPesquisaUser));
    }, [stringPesquisaUser]);


    const executarPesquisa = (evento) => {
        evento.preventDefault()
        setError("");
        if (stringPesquisaUser.length >= 44) {
            setEstagio(EstagioEnum.RESULT);
            validarBoleto(stringPesquisaUser);
        }
    }

    function getCor(result){
        if (ResultadoValidarBoletoEnum[result] == ResultadoValidarBoletoEnum.BOLETO_VALIDO){
            return "verde";
        }
        if (ResultadoValidarBoletoEnum[result] == ResultadoValidarBoletoEnum.TERMO_INVALIDO){
            return "laranja";
        }
        return "vermelho";
    }
    async function validarBoleto(stringPesquisa) {
        try {
            setLoading(true);
            const data = await CallbackApiService.verificarBoleto(stringPesquisa);
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError("Não foi possível realizar a validação no momento, tente mais tarde.")
        }
    }

    function getEstagioInicial() {
        if (navigator.mediaDevices
            && typeof navigator.mediaDevices.getUserMedia === 'function'
            && window.innerWidth <= 768) {
            //return EstagioEnum.INIT_CAMERA; não utilizar camera nesta versao
            return EstagioEnum.PESQUISAR;
        }
        return EstagioEnum.PESQUISAR;
    }

    function onVoltar() {
        setStringPesquisaUser("");
        setResultApi({});
        setError("");
        setEstagio(EstagioEnum.PESQUISAR);
        setBloquearBotao(true);
    }



    function formatarDigitos(value) {
        const mascara = "#####.##### #####.###### #####.###### # ##############";
        if (!value)
            return value;
        // remove caracteres nao numericos
        var digitos = String(value.replace(/[^0-9]+/g, ""));
        if (digitos.length === 44 || digitos.length === 47) {
            setBloquearBotao(false)
        } else {
            setBloquearBotao(true)
        }
        //se tamanho igual ao de codigo de barras retornar sem mascara 
        if (digitos.length < 2 || digitos.length === 44) return digitos;
        //ignorar digitos apos 47 posicoes
        if (digitos.length > 47) {
            digitos = digitos.substring(0, 47);
        }
        var indMascara = 0;
        var indCampo = 0;
        var digitoMascara = "";
        var saida = "";
        while (indCampo < digitos.length) {
            if (indMascara < mascara.length) {
                digitoMascara = mascara.charAt(indMascara);
            } else {
                digitoMascara = "#";
            }

            if (digitoMascara === '#') {
                saida = saida + digitos.charAt(indCampo++)
            } else {
                saida = saida + mascara.charAt(indMascara)
            }
            indMascara++;
        }
        return saida;
    }

    const BotaoVoltar = () => (
        <div className="row ">
            <img src={VoltarSvg} className="img-voltar" onClick={() => onVoltar()} />
        </div>
    )

    return (
        <section >
            <div >
                {
                    estagio !== EstagioEnum.SCAN &&
                    <div className="row painel-topo">
                        <div className="col-md-6 col-xm-12 linha-topo">
                            <img src={LogoUnimedPng} className="img-logo-unimed" />
                        </div>
                        <div className="col-md-6 col-xm-12 linha-topo">
                            <img src={ValidarBoletoSvg} className="img-validar-boleto" />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-6 col-xm-12">
                        {(estagio === EstagioEnum.PESQUISAR && largura > 768) && <img src={BarrasVerticaisSvg} className="img-barras-verticais" />}
                        {(estagio === EstagioEnum.INIT_CAMERA || (estagio === EstagioEnum.PESQUISAR && largura <= 768)) && <PainelAvisoSeguranca />}
                    </div>
                </div>
                <div className="row painel-2">
                    <div className="col-md-6 col-xm-12 painel2a">
                        {estagio === EstagioEnum.RESULT && <BotaoVoltar />}

                        <form onSubmit={executarPesquisa}>
                            {estagio < EstagioEnum.PESQUISAR && <PainelCameraDetect setEstagio={setEstagio} setStringPesquisaUser={setStringPesquisaUser} />}

                            {estagio >= EstagioEnum.PESQUISAR &&
                                <>
                                    <div>
                                        <p className='label-codigo-boleto'>
                                            Digite ou cole o código do boleto
                                            {(largura <= 768 && estagio === EstagioEnum.PESQUISAR) && <BoletoCodigoHelp largura={largura} />}
                                        </p>
                                    </div>

                                    <div className="row">
                                        <div className="campo-pesquisa">
                                            <CampoTexto
                                                label=""
                                                obrigatorio={true}
                                                disabled={estagio === 5 ? true : false}
                                                minLength="44"
                                                placeholder=""
                                                valor={stringPesquisaUser}
                                                novoValor={valor => setStringPesquisaUser(valor)}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {(estagio === EstagioEnum.PESQUISAR && largura > 768) &&
                                <BoletoCodigoHelp largura={largura} />
                            }
                            {estagio === EstagioEnum.PESQUISAR &&
                                <div className="row ">
                                    <div className="botao-validar">
                                        <Botao disabled={bloquearBotao}> Validar boleto
                                        </Botao>
                                    </div>
                                </div>
                            }
                            <div className="row ">
                                {resultApi.mensagem &&
                                    <div className="row ">
                                        <span className={`alert-result resultado-${getCor(resultApi.resultado)}`} >
                                            {resultApi.mensagem}
                                        </span>
                                    </div>
                                }
                                {error &&
                                    <div className="row ">
                                        <span className="alert-error" >
                                            {error}
                                        </span>
                                    </div>
                                }
                                {loading && <div className="row justify-content-center"><Loading3Sharps /></div>}
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-xm-12 painel2b">
                        {(estagio === EstagioEnum.PESQUISAR && largura > 700) && <PainelAvisoSeguranca />}
                        {estagio === EstagioEnum.RESULT && <PainelCanaisOficiais />}
                    </div>
                </div>
            </div>
            {estagio !== EstagioEnum.SCAN &&
                <div id="footer">
                    <span>© Copyright 2024 -  Unimed Goiânia - Todos os Direitos Reservados</span>
                </div>
            }
        </section>
    );
}
export default PaginaValidarBoleto;
